import React, { useContext, useEffect } from "react"
import { CART_ACTIONS, PRODUCTS } from "../constants"
import { Context } from "../contexts/StateStore"

const useCart = () => {
  const [state, dispatch] = useContext(Context)
  const { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_ITEM, SET_TOTAL_PRICE } =
    CART_ACTIONS

  useEffect(() => {
    let total = 0
    state.cart.map((item) => {
        total += item.price * item.qty
    })

    setPrice(parseInt(total));

    try {
      let tempCart = state?.cart?.map((i) => {
          return {
            name: i.title,
            price: (i.price / 100),
            quantity: i.qty,
          }
      })
      window.zonos.quote({
        items: tempCart,
        currencyCode: 'USD',
      });
      
    } catch (error) {}
  }, [state?.cart])

  // ADD to cart
  const addToCart = variant => {
    let selectedItem = PRODUCTS.find(product => product.sku == variant)

    // if already exists just update qty
    let itemAlreadyExitsInCart = state.cart.find(
      product => product.sku == selectedItem.sku
    )
    if (itemAlreadyExitsInCart) {
      updateCartItem({
        sku: variant,
        key: "qty",
        value: (itemAlreadyExitsInCart.qty += 1),
      })
      return
    }

    dispatch({
      type: ADD_TO_CART,
      payload: {
        id: selectedItem.id,
        title: selectedItem.title,
        desc: selectedItem.desc,
        price: selectedItem.price,
        size: selectedItem.size,
        name: selectedItem.name,
        imgSrc: selectedItem.imgSrc,
        sku: selectedItem.sku,
        qty: 1,
      },
    })
  }

  // remove form cart
  const removeFromCart = sku => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: {
        sku,
      },
    })
  }

  // update item in cart
  const updateCartItem = payload => {
    dispatch({
      type: UPDATE_CART_ITEM,
      payload,
    })
  }

  // set total price
  const setPrice = total => {
    dispatch({
      type: SET_TOTAL_PRICE,
      payload: {
        total
      },
    })
  }

  const getPrice = price => {
    return (price / 100).toFixed(2)
  }

  return {
    addToCart,
    removeFromCart,
    updateCartItem,
    setPrice,
    getPrice
  }
}

export default useCart
