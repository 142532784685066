import { Link } from "gatsby"
import React, { useContext } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Context } from "../contexts/StateStore"
import useCart from "../hooks/useCart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan, faCartShopping } from "@fortawesome/free-solid-svg-icons"
import CartItemPreview from "../components/CartItemPreview/CartItemPreview"

const CartPage = () => {
  const [state] = useContext(Context)
  const { updateCartItem, removeFromCart } = useCart()

  const updateItemQuantity = (sku, value) => {
    updateCartItem({
      sku: sku,
      key: "qty",
      value: value,
    })
  }

  if (state?.cart?.length === 0) {
    return (
      <Layout>
        <Seo title="Cart" />
        <Container className="container py-5 text-white text-center">
          <Row>
            <Col md="12">
              <p className="h2 bank-font">Your cart is empty.</p>
              <FontAwesomeIcon
                className="mt-3"
                icon={faCartShopping}
                size="4x"
                aria-hidden="true"
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Cart" />
      <Container className="container py-5 text-white">
        <Row>
          <Col md="12">
            <ul className="list-unstyled">
              {state?.cart?.map((item, index) => (
                <li className="mb-3" key={index}>
                  <Row>
                    <Col md="2" className="col-3">
                     <CartItemPreview product={item.sku} />
                    </Col>
                    <Col md="8" className="col-8">
                      <p className="mb-0 gold-text mb-3">
                        {item.title} ({item.name})
                      </p>
                      <p className="mb-0 h5">
                        Price: ${((item.price * item.qty) / 100).toFixed(2)}
                      </p>
                      <Form.Group
                        as={Row}
                        controlId="formQuantity"
                        className="align-items-center"
                      >
                        <Form.Label column md="auto" className="pe-0">
                          Quantity:
                        </Form.Label>
                        <Col sm="2">
                          <Form.Select
                            size="sm"
                            className="bg-light gold-text"
                            value={item.qty}
                            onChange={e =>
                              updateItemQuantity(
                                item.sku,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            <option defaultChecked value={null} disabled={true}>
                              Select Quantity
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Button
                        variant="light"
                        className="gold-text text-white mt-3 mt-md-0"
                        onClick={() => removeFromCart(item.sku)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </Button>
                    </Col>
                    <Col className="col-12">
                      <hr className="w-100" />
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md="4">
            <Link to="/checkout" className="btn btn-light gold-text w-100">
              Checkout Now
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CartPage
